.date-picker {
  width: 100%;
  padding-top: 5.5rem;
  text-align: center;
}

.date-picker .head {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0rem 0.06rem 0.11rem 0rem rgba(0,0,0,0.2);
}

.date-picker .head .title-wrapper {
  text-align: left;
  width: 100%;
  padding: 0.3rem 0 0.2rem 0.6rem;
}

.date-picker .head .title-wrapper .text {
  font-weight: 600;
  font-size: 0.4rem;
  color: #333333;
}

.date-picker .head .title-wrapper .desc {
  font-size: 0.32rem;
  color: #999999;
}

.date-picker .head .tab-wrapper {
  width: 100%;
  display: flex;
  font-size: 0.37rem;
  padding: 0.35rem 0;
  border-top: 0.01rem solid rgba(204,204,204,0.4);
  border-bottom: 0.01rem solid rgba(204,204,204,0.4);
  justify-content: space-between;
}

.date-picker .head .tab-wrapper .tab {
  flex: 1;
  text-align: center;
  font-size: 0.4rem;
}

.date-picker .head .tab-wrapper .active {
  color: #1f93fd;
}

.date-picker .head .tips {
  width: 100%;
  padding: 0.3rem 0;
  color: #666666;
  font-size: 0.32rem;
}

.date-picker .head .weekDay-wrapper {
  width: 96%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: space-around;
  color: #666666;
}

.date-picker .head .weekDay-wrapper .weekDay-item-wrapper {
  width: 13.6vw;
  height: 13.6vw;
  background-color: #fafafa;
  display: flex;
  padding: 0.15rem 0;
  flex-direction: column;
  justify-content: space-around;
  font-size: 0.36rem;
}

.date-picker .head .weekDay-wrapper .active {
  background-color: #e3f3ff;
  color: #1688f2;
}

.date-picker .head .weekDay-wrapper .forbiddenActive {
  background-color: #fef0f0;
  color: #f56c6c;
}

.date-picker .slide-right-enter-active {
  transition: all 0.2s ease;
}

.date-picker .slide-right-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.date-picker .slide-right-enter,
.date-picker .slide-right-leave-to {
  opacity: 0;
}

.date-picker .slide-left-enter-active {
  transition: all 0.2s ease;
}

.date-picker .slide-left-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.date-picker .slide-left-enter,
.date-picker .slide-left-leave-to {
  opacity: 0;
}

.date-picker .container {
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.date-picker .container .col {
  width: 13.6vw;
  display: flex;
  flex-direction: column;
}

.date-picker .container .col .pickItem {
  width: 13.6vw;
  height: 13.6vw;
  line-height: 13.6vw;
  background-color: #fafafa;
  color: #999999;
  font-size: 0.36rem;
}

.date-picker .container .col .pickItem:not(:first-child) {
  margin-top: 0.11vw;
}

.date-picker .container .col .active {
  background-color: #e3f3ff;
  color: #1688f2;
}

.date-picker .container .col .forbiddenActive {
  background-color: #fef0f0;
  color: #f56c6c;
}

.date-picker .foot {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 0.03rem solid #ededed;
  display: flex;
}

.date-picker .foot .submit-btn {
  background-color: #1688f2;
  color: #ffffff;
  width: 100%;
  font-size: 0.4rem;
  padding: 0.35rem 0;
}

.date-picker .foot .cancle {
  background-color: #ffffff;
  color: #333333;
}